<template>
    <div class="module_head">
        <div class="module_head_left">
            <div class="module_head_left_div"></div>
            <div class="module_head_left_title">{{title}}</div>
        </div>
        <div class="module_head_right" @click="more_func()">
            更多<van-icon name="arrow" />
        </div>
    </div>
</template>
<script>
import { defineComponent } from "@/plugin/importCommonUse";
// import { useContext } from "vue";
export default defineComponent({
    props:['module_head_title',"XSFC_more_func()"],
    setup(props,ctx) {
        const title = props.module_head_title;
        const more_func =  ()=>{
            ctx.emit('more_func');
        }
        return{title,more_func}
    },
})
</script>
<style>
    .module_head{
        width: 100%;
        display: flex;
        justify-content:space-between;
        align-items: center;
    }
    .module_head_left{
        display: flex;
        align-items: center;
        margin: 2.4%;
    }
    .module_head_left_div{
        height: 25px;
        width: 4px;
        background: #B34136;
        margin-right: 5px;
    }
    .module_head_left_title{
        white-space: nowrap;
        font-weight: 600;

    }
    .module_head_right{
        margin: 2.4%;
        color: #666666;
        font-size: 13px;
    }
</style>