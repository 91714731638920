<template>
    <div class="initiationApply">
       <img style="width: 100%;" src="@/assets/index/jianjie.jpg" alt="">
    </div>
</template>

<script>
import {
  defineComponent,reactive
} from "@/plugin/importCommonUse";
import { useRouter } from "vue-router";
import moduleHead from "@/components/moduleHead/index.vue"
export default defineComponent({
    components:{
        moduleHead
    },
    setup() {
        let router = useRouter();
        let skip = (url)=>{
            router.push(url);
        }

        return {
            skip
        };
    },
})
</script>
<style>
.initiationApply{
    width: 100%;
    /* border-bottom: 1px solid #dcdee2; */
}
</style>
